import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AppContainer } from "./styles.js";

import { Header } from "../Components/Header";
// import { Footer } from "../Components/Footer/index.js";

import { Catalog } from "../Page/Catalog";
import { Auth } from "../Page/Auth";
import { Tables } from "../Page/Tables";
import { ProductDetail } from "../Page/ProductDetail";

import { UserProvider } from "../Context/User/UserContext";

const App = () => {
  return (
    <AppContainer>
      <BrowserRouter>
        <UserProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Catalog />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/product-detail/:id" element={<ProductDetail />} />
          </Routes>
          {/* <Footer /> */}
        </UserProvider>
      </BrowserRouter>
    </AppContainer>
  );
};

export default App;
