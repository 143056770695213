import styled from "styled-components";

export const ListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 10px;
  align-items: center;

  ul {
    width: 100%;
    max-height: 200px;

    padding: 5px 10px;

    display: flex;
    flex-direction: column;

    gap: 10px;
    align-items: center;

    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.background_03};

    overflow-y: auto;

    li {
      width: 100%;
      height: 30px;

      display: flex;
      flex-direction: row;

      gap: 10px;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      text-align: center;
      list-style-type: none;

      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.background_01};

      &:hover {
        background-color: #ff7f7f;
      }
    }
  }

  span {
    color: red;
    text-align: center;
  }
`;

export const SubListContainer = styled.div`
  width: 100%;

  padding: 15px 10px 10px;
  margin-top: 15px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 5px;

  border-radius: 10px;

  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border_01};

  position: relative;

  h1 {
    position: absolute;
    top: -12.5px;
    left: 10px;

    font-size: 1.125rem;

    background-color: ${({ theme }) => theme.colors.background_02};
  }
`;
