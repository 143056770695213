import React from "react";
import { TableCategoriesContainer } from "./styles";

import Table from "../../../../../Components/Table";
import ToggleSwitch from "../../../../../Components/ToogleSwitch";
import Button from "../../../../../Components/Button";

import imgUpdate from "../../../../../Assets/update.png";
import imgDelete from "../../../../../Assets/delete.png";

import {
  DELETE_CATEGORY,
  LIST_CATEGORIES,
  UPDATE_CATEGORY,
} from "../../../../../Api/Categoria";

import useUser from "../../../../../Context/User/useUser";
import ModalCreate from "../ModalCreate";
import ModalUpdate from "../ModalUpdate";

const TableCategories = () => {
  const [categories, setCategories] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [, setLoading] = React.useState(true);

  const [categoryId, setCategoryId] = React.useState(null);
  const [isOpenModalCreate, setIsOpenModalCreate] = React.useState(false);
  const [isOpenModalUpdate, setIsOpenModalUpdate] = React.useState(false);

  const { featchUserToken } = useUser();

  React.useEffect(() => {
    featchUserToken();
  }, [featchUserToken]);

  const fetchCategories = React.useCallback(async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const { url, options } = LIST_CATEGORIES(token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setCategories(responseJson.categorias);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError(
        "Falha na listagem de categorias. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleUpdateCategory = async (id, highlights, visible) => {
    if (!id) return;

    const token = window.localStorage.getItem("token");

    let body;
    if (highlights === 1 || highlights === 0) {
      body = { destaque: highlights };
    } else if (visible === 1 || visible === 0) {
      body = { visivel: visible };
    } else {
      return;
    }

    const { url, options } = UPDATE_CATEGORY(id, body, token);

    const response = await fetch(url, options);
    const responseJson = await response.json();

    if (responseJson.ok === "true") {
      let status;

      console.log(highlights, visible);
      if (highlights === 1 || highlights === 0) {
        if (highlights === 1) {
          status = 0;
        } else {
          status = 1;
        }

        setCategories((prevCategory) =>
          prevCategory.map((category) =>
            category.id === id ? { ...category, destaque: status } : category
          )
        );
      } else if (visible === 1 || visible === 0) {
        if (visible === 1) {
          status = 0;
        } else {
          status = 1;
        }

        setCategories((prevCategory) =>
          prevCategory.map((category) =>
            category.id === id ? { ...category, visivel: status } : category
          )
        );
      }
    } else {
      if (responseJson.erro) window.alert(responseJson.erro);
      if (responseJson.falha) window.alert(responseJson.falha);
    }
  };

  const handleDeleteCategory = async (id) => {
    if (!id) return;

    const confirmed = window.confirm(
      "Tem certeza de que deseja excluir este produto?"
    );

    if (!confirmed) {
      return;
    }

    const token = window.localStorage.getItem("token");

    const { url, options } = DELETE_CATEGORY(id, token);

    const response = await fetch(url, options);
    const responseJson = await response.json();

    if (responseJson.ok === "true") {
      await fetchCategories();
    } else {
      if (responseJson.erro) window.alert(responseJson.erro);
      if (responseJson.falha) window.alert(responseJson.falha);
    }
  };

  return (
    <TableCategoriesContainer>
      <div>
        <Button type="button" onClick={() => setIsOpenModalCreate(true)}>
          Novo Categoria
        </Button>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Ordem</th>
            <th>Destaque</th>
            <th>Visualização</th>
            <th>Ações</th>
          </tr>
        </thead>
        {error ? (
          <tbody>
            <strong>{error}</strong>
          </tbody>
        ) : (
          <tbody>
            {categories.map((category, index) => (
              <tr key={index}>
                <td>{category?.nome}</td>
                <td>{category?.ordem ? category?.ordem : "N/A"}</td>
                <td>
                  <ToggleSwitch
                    isActive={category?.destaque === 1 ? true : false}
                    onToggle={() => {
                      handleUpdateCategory(category?.id, category?.destaque);
                    }}
                  />
                </td>
                <td>
                  <ToggleSwitch
                    isActive={category?.visivel === 1 ? true : false}
                    onToggle={() => {
                      handleUpdateCategory(category?.id, "", category?.visivel);
                    }}
                  />
                </td>
                <td>
                  <Button
                    type="button"
                    title="Atualizar produto"
                    onClick={() => {
                      if (!category?.id) return;
                      setCategoryId(category?.id);
                      setIsOpenModalUpdate(true);
                    }}
                  >
                    <img
                      width="26"
                      height="26"
                      src={imgUpdate}
                      alt="Atualizar Produto"
                    />
                  </Button>
                  <Button
                    type="button"
                    title="Excluir produto"
                    onClick={() => handleDeleteCategory(category?.id)}
                  >
                    <img
                      width="26"
                      height="26"
                      src={imgDelete}
                      alt="Apagar Produto"
                    />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      <ModalCreate
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        fetchCategories={fetchCategories}
      />
      <ModalUpdate
        isOpen={isOpenModalUpdate}
        setIsOpen={setIsOpenModalUpdate}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        fetchCategories={fetchCategories}
      />
    </TableCategoriesContainer>
  );
};

export default TableCategories;
