import React from "react";
import PropTypes from "prop-types";

import { StyledTable, TableWrapper } from "./styles";

const Table = ({ children }) => {
  return (
    <TableWrapper>
      <StyledTable>{children}</StyledTable>
    </TableWrapper>
  );
};

Table.propTypes = {
  children: PropTypes.node,
};

export default Table;
