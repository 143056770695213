import React from "react";

import { ModalImagesContainer, ModalImagesSubContainer } from "./styles";

import Modal from "../../../../../Components/Modal";
import Button from "../../../../../Components/Button";
import ImageUpload from "../../../../../Components/ImageUpload";

import imgShoppingBasket from "../../../../../Assets/shopping-basket(black).svg";

import { IMAGE_PRODUCT } from "../../../../../Api/Produto";
import {
  CREATE_PRODUCT_IMAGE,
  DELETE_PRODUCT_IMAGE,
} from "../../../../../Api/ProdutoImagens";

const ModalImages = ({
  isOpen,
  setIsOpen,
  error,
  setError,
  loading,
  setLoading,
  findProduct,
  listProducts,
  productData,
}) => {
  const [newImage, setNewImage] = React.useState(null);
  const [newImagePreview, setNewImagePreview] =
    React.useState(imgShoppingBasket);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    setError("");
    setLoading(false);
    setNewImage(null);
    setNewImagePreview(imgShoppingBasket);
  }, [setIsOpen, setError, setLoading, setNewImage, setNewImagePreview]);

  const handleAddImageToProduct = async () => {
    if (!productData?.id || !newImage) {
      setError("Escolha uma imagem");
      return;
    }

    try {
      setLoading(true);
      setError("");

      const formData = new FormData();
      formData.append("imagem", newImage);
      formData.append("produto_id", productData?.id);

      const token = window.localStorage.getItem("token");
      const { url, options } = CREATE_PRODUCT_IMAGE(formData, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        findProduct(productData?.id);
        listProducts();
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Erro ao associar a imagem ao produto. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImageToProduct = async (imageId) => {
    if (!imageId) {
      setError("Escolha uma imagem");
      return;
    }

    try {
      setLoading(true);
      setError("");

      const confirmed = window.confirm(
        "Tem certeza de que deseja excluir esta imagem?"
      );

      if (!confirmed) {
        return;
      }

      const token = window.localStorage.getItem("token");
      const { url, options } = DELETE_PRODUCT_IMAGE(imageId, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        findProduct(productData?.id);
        listProducts();
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Erro ao desassociar a imagem ao produto. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={() => {}}>
      <ModalImagesContainer>
        <h1>Imagens</h1>
        <ImageUpload
          label="Escolha uma Imagem"
          loading={loading}
          setImage={setNewImage}
          imagePreview={newImagePreview}
          setImagePreview={setNewImagePreview}
          onChange={() => {}}
        />
        <Button
          type="button"
          disabled={loading}
          onClick={handleAddImageToProduct}
        >
          Adicionar Imagem
        </Button>
        {error && <span>{error}</span>}
        <ModalImagesSubContainer>
          <h3>Imagens Cadastradas</h3>
          {productData?.imagens.length > 0 ? (
            productData?.imagens.map((i, index) => (
              <div key={index}>
                <img
                  key={index}
                  alt="Imagem do Produto"
                  src={IMAGE_PRODUCT(i?.nome)}
                />
                <Button
                  type="button"
                  disabled={loading}
                  title="Apagar item do produto"
                  onClick={() => handleRemoveImageToProduct(i?.id)}
                >
                  -
                </Button>
              </div>
            ))
          ) : (
            <h4>Nenhuma imagem cadastrada.</h4>
          )}
        </ModalImagesSubContainer>
      </ModalImagesContainer>
    </Modal>
  );
};

export default ModalImages;
