import styled from "styled-components";

export const TableProuctsContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: center;

  > div:nth-child(1) {
    width: 100%;
    height: fit-content;

    padding: 5px 12.5px;

    display: flex;
    align-items: center;
    justify-content: start;

    gap: 5px;

    background-color: ${({ theme }) => theme.colors.color_01};

    input {
      width: 50%;
      height: 40px;
      padding: 5px 10px;
      font-size: 1.125rem;
    }

    button {
      width: 50%;
      height: 40px;
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.color_02};
      background-color: ${({ theme }) => theme.colors.color_02};
      &:hover {
        background-color: ${({ theme }) => theme.colors.color_03};
      }
    }
  }
`;
