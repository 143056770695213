import React from "react";
import { useNavigate } from "react-router-dom";

import { IMAGE_PRODUCT, CATALOG_PRODUCTS } from "../../Api/Produto";

import { CatalogContainer, CatalogSubContainer } from "./styles";

import CatalogItem from "./Components/CatalogItem";
import Loading from "../../Components/Loading";
import SideBar from "./Components/SideBar";
// import Carousel from "../../Components/Carousel";

export const Catalog = () => {
  // const images = [
  //   "http://localhost:3333/produtos/imagem_produto/01fototeste.png",
  //   "http://localhost:3333/produtos/imagem_produto/02fototeste.png",
  //   "http://localhost:3333/produtos/imagem_produto/03fototeste.png",
  //   "http://localhost:3333/produtos/imagem_produto/04fototeste.png",
  // ];

  const navigate = useNavigate();

  const [catalog, setCatalog] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [isOpenSideBar, setIsOpenSideBar] = React.useState(true);
  const [categoryId, setCategoryId] = React.useState(3);
  const [nameProduct, setNameProduct] = React.useState("");
  const [selectCategory, setSelectCategory] = React.useState("Cestas");

  const openProductDetails = async (productId) => {
    if (productId) navigate(`/product-detail/${productId}`);
  };

  const catalogProducts = React.useCallback(async () => {
    try {
      setError("");
      setLoading(true);

      const { url, options } = CATALOG_PRODUCTS(
        "",
        nameProduct,
        "1",
        nameProduct ? null : categoryId
      );

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setCatalog(responseJson.produtos);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Falha no servidor. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, [categoryId, nameProduct]);

  React.useEffect(() => {
    catalogProducts();
  }, [catalogProducts]);

  const renderCatalogContent = () => {
    if (loading === true) {
      return <Loading />;
    }

    if (error) {
      return <strong>{error}</strong>;
    }

    if (catalog.length > 0) {
      return catalog.map((item) => (
        <CatalogItem
          key={item?.id}
          onClick={() => openProductDetails(item.id)}
          productName={item?.nome}
          numberItens={item?.numero_itens}
          productPrice={item?.preco}
          productImage={IMAGE_PRODUCT(item?.imagem_produto)}
          highlight={item?.categoria_destaque}
        />
      ));
    }
  };

  return (
    <CatalogContainer>
      {/* <Carousel images={images} /> */}
      <div></div>
      <div>
        <p>
          Pesquisando produto
          {!nameProduct && ` - Categoria: `}{" "}
          <strong onClick={() => setIsOpenSideBar(!isOpenSideBar)}>
            {!nameProduct && selectCategory}
          </strong>
          {nameProduct && ` - Nome: `}{" "}
          <strong onClick={() => setIsOpenSideBar(!isOpenSideBar)}>
            {nameProduct}
          </strong>
        </p>
      </div>
      <CatalogSubContainer>
        <SideBar
          isOpenSideBar={isOpenSideBar}
          setIsOpenSideBar={setIsOpenSideBar}
          nameProduct={nameProduct}
          setNameProduct={setNameProduct}
          setSelectCategory={setSelectCategory}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
        />
        <div>{renderCatalogContent()}</div>
      </CatalogSubContainer>
    </CatalogContainer>
  );
};
