import React from "react";

import Modal from "../../../../../Components/Modal";
import Input from "../../../../../Components/Input";
import Button from "../../../../../Components/Button";

import { CREATE_CATEGORIES } from "../../../../../Api/Categoria";

import useForm from "../../../../../Hook/useForm";

const ModalCreate = ({ isOpen, setIsOpen, fetchCategories }) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const name = useForm(true);
  const order = useForm(true);

  const onClose = () => {
    setIsOpen(false);
    setError("");
    name.setValue("");
    order.setValue("");
  };

  const handleCreateCategory = async () => {
    try {
      setError("");
      setLoading(true);

      if (name.validation() && order.validation()) {
        const token = window.localStorage.getItem("token");

        const { url, options } = CREATE_CATEGORIES(
          {
            nome: name.value,
            ordem: order.value,
          },
          token
        );

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          await fetchCategories();
          onClose();
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (error) {
      setError(
        "Falha no cadastro. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleCreateCategory}>
      <h1>Cadastro de Categoria</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Nome da Categoria"
        {...name}
        label="Nome"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Ordem da Categoria"
        {...order}
        label="Ordem"
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Button type="submit" disabled={loading}>
        Salvar
      </Button>
    </Modal>
  );
};

export default ModalCreate;
