import styled from "styled-components";

const shouldForwardProp = (prop) => !["isOpen"].includes(prop);

export const SideBarContainer = styled.div.withConfig({
  shouldForwardProp,
})`
  width: ${({ isOpen }) => (isOpen ? "120px" : "50px")};
  height: calc(100% + 40px);

  padding: 10px 5px;

  display: flex;
  flex-direction: column;

  /* position: absolute;
  left: 0;
  top: 0; */

  border-radius: 0px 10px 0px 0px;
  background-color: ${({ theme }) => theme.colors.color_01};

  transition: width 0.3s ease;

  z-index: 5;

  cursor: pointer;
  user-select: none;

  > div:nth-child(1) {
    width: 100%;
    height: fit-content;

    margin-bottom: 10px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    transition: 0.3s ease;

    img {
      border-radius: 5px;

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      }
    }
  }

  > div:nth-child(2) {
    padding: 5px;

    display: flex;
    flex-direction: column;

    gap: 10px;

    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.background_01};

    input {
      height: 30px;
      padding: 5px;
      font-size: 0.85rem;
    }

    img {
      margin: auto;
    }
  }
`;

export const ProductCategoryContainer = styled.div`
  width: 100%;
  height: fit-content;

  padding: 10px 5px;

  display: flex;
  flex-direction: column;

  gap: 10px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background_02};

  ul {
    display: flex;
    flex-direction: column;

    gap: 5px;
  }

  .destaque {
    position: relative;
    border: 2px solid #ffd700;
  }

  li {
    padding: 5px;
    list-style: none;

    font-size: 0.7rem;

    text-align: center;

    border-radius: 5px;

    background-color: ${({ theme }) => theme.colors.background_01};

    &:hover {
      background-color: ${({ theme }) => theme.colors.color_01};
    }

    > img {
      position: absolute;
      top: -5px;
      left: -5px;
      border-radius: 50%;
      background-color: #ffd700;
    }
  }

  .active {
    background-color: ${({ theme }) => theme.colors.color_01};
  }
`;
