import React from "react";

import { ProductCategoryContainer, SideBarContainer } from "./styles";

import IconClose from "../../../../Assets/close.svg";
import IconFilter from "../../../../Assets/filter.svg";
import IconSearch from "../../../../Assets/search.png";
import IconStar from "../../../../Assets/star(white).png";

import { LIST_CATEGORIES } from "../../../../Api/Categoria";

const SideBar = ({
  isOpenSideBar,
  setIsOpenSideBar,
  nameProduct,
  setNameProduct,
  setSelectCategory,
  categoryId,
  setCategoryId,
}) => {
  const [categories, setCategories] = React.useState([]);

  const [, setError] = React.useState(null);
  const [, setLoading] = React.useState(true);

  const fetchCategories = React.useCallback(async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const { url, options } = LIST_CATEGORIES(token, "1");

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setCategories(responseJson.categorias);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Falha na listagem de categorias. Tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <SideBarContainer
      isOpen={isOpenSideBar}
      onClick={() => {
        if (isOpenSideBar === false) setIsOpenSideBar(true);
      }}
    >
      <div>
        <img width={30} height={30} src={IconFilter} alt="Icone Filtro" />
        {isOpenSideBar && (
          <img
            width={30}
            height={30}
            src={IconClose}
            alt="Icone Fechar"
            onClick={() => setIsOpenSideBar(false)}
          />
        )}
      </div>
      {isOpenSideBar && (
        <div>
          <img
            width={40}
            height={40}
            src={IconSearch}
            alt="Icone de Pesquisa"
          />
          <input
            placeholder="Nome"
            type="text"
            label="Nome"
            value={nameProduct}
            onChange={(e) => setNameProduct(e.target.value)}
          />
          <ProductCategoryContainer>
            <ul>
              {categories &&
                categories.map((category) => (
                  <li
                    key={category.id}
                    onClick={() => {
                      setSelectCategory(category.nome);
                      setCategoryId(category.id);
                    }}
                    className={`
                      ${
                        categoryId === category.id && !nameProduct
                          ? "active"
                          : ""
                      } 
                      ${category?.destaque === 1 ? "destaque" : ""}
                    `.trim()}
                  >
                    {category?.destaque ? (
                      <img width={15} src={IconStar} alt="Icone de Estrela" />
                    ) : null}
                    {category.nome}
                  </li>
                ))}
            </ul>
          </ProductCategoryContainer>
        </div>
      )}
    </SideBarContainer>
  );
};

export default SideBar;
