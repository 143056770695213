import React from "react";
import { useNavigate } from "react-router-dom";

import { HeaderContainer } from "./styles";

import Logo from "../../Assets/SeuGift.png";
import Menu from "../../Assets/menu.png";
import WhatsApp from "../../Assets/whatsapp.png";

import { ENDERECO_GOOGLEMAPS, WHATSAPP_SEUGIFT } from "../../Helper/link";

export const Header = () => {
  const navigate = useNavigate();

  const [menuVisible, setMenuVisible] = React.useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <HeaderContainer>
      <div>
        <img
          width={50}
          src={Logo}
          alt="SeuGift Logo"
          onClick={() => navigate("/")}
        />
        <a
          href="/"
          rel="noreferrer"
          target="_parent"
          title="Voltar para a página inicial"
        >
          SeuGift
        </a>
        <img width={50} src={Menu} alt="Menu" onClick={toggleMenu} />
      </div>
      <div>
        <p>Fortalecendo Relacionamentos</p>
        <div>
          <a href={WHATSAPP_SEUGIFT} target="_blank" rel="noreferrer">
            Pedidos (19 98767-8840)
            <img src={WhatsApp} alt="WhatsApp Icon" height={20} />
          </a>
          <a target="_blank" rel="noreferrer" href={ENDERECO_GOOGLEMAPS}>
            Loja - Rua Almirante Tamandaré, 862 - Indaiatuba - SP
          </a>
        </div>
      </div>
      {menuVisible && (
        <div className="dropdown-menu active">
          <p
            onClick={() => {
              navigate("/auth");
              setMenuVisible(false);
            }}
          >
            Autenticação
          </p>
          {/* Você pode adicionar mais opções aqui */}
        </div>
      )}
    </HeaderContainer>
  );
};
