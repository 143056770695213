import React from "react";

import Modal from "../../../../../Components/Modal";
import Input from "../../../../../Components/Input";
import Button from "../../../../../Components/Button";
import Textarea from "../../../../../Components/Textarea";
import ImageUpload from "../../../../../Components/ImageUpload";

import { CREATE_PRODUCT } from "../../../../../Api/Produto";

import useForm from "../../../../../Hook/useForm";

import imgBasket from "../../../../../Assets/shopping-basket(black).svg";
import imgDelete from "../../../../../Assets/delete(black).svg";
import { ListContainer, SubListContainer } from "./styles";
import Select from "../../../../../Components/Select";

const ModalCreate = ({
  isOpen,
  setIsOpen,
  error,
  setError,
  loading,
  setLoading,
  items,
  categories,
  listProducts,
}) => {
  const owner = useForm(true);
  const name = useForm(true);
  const price = useForm("price");
  const description = useForm(false);

  const [currentStock, setCurrentStock] = React.useState(1);
  const [minimumStock, setMinimumStock] = React.useState(1);

  const [image, setImage] = React.useState(null);
  const [imagePreview, setImagePreview] = React.useState(imgBasket);

  const [itemId, setItemId] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const [categoryId, setCategoryId] = React.useState(null);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const onClose = () => {
    setIsOpen(false);
    setError("");
    setLoading(false);
    owner.setValue("");
    name.setValue("");
    price.setValue("");
    description.setValue("");
    setCurrentStock("1");
    setMinimumStock("1");
    setImage("");
    setImagePreview(imgBasket);
    setItemId(null);
    setSelectedItems([]);
    setCategoryId(null);
    setSelectedCategories([]);
  };

  const addItemToProduct = () => {
    const item = items.find((i) => JSON.stringify(i.id) === itemId);

    if (!item) return;

    const itemExists = selectedItems.some(
      (selectedItem) => selectedItem.id === item.id
    );

    if (itemExists) return;

    setSelectedItems((prevItems) => [
      ...prevItems,
      { id: item.id, nome: item.nome },
    ]);

    setItemId(null);
  };

  const removeItemToProduct = (id) => {
    setSelectedItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const addCategoryToProduct = () => {
    const category = categories.find(
      (i) => JSON.stringify(i.id) === categoryId
    );

    if (!category) return;

    const categoryExists = selectedCategories.some(
      (selectedItem) => selectedItem.id === category.id
    );

    if (categoryExists) return;

    setSelectedCategories((prevCategories) => [
      ...prevCategories,
      { id: category.id, nome: category.nome },
    ]);

    setCategoryId(null);
  };

  const removeCategoryToProduct = (id) => {
    setSelectedItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const createProduct = async () => {
    try {
      setError("");
      setLoading(true);

      if (
        owner.validation() &&
        name.validation() &&
        price.validation() &&
        currentStock > 0 &&
        minimumStock > 0 &&
        image != null
      ) {
        if (itemId) {
          setError("Adicione o item selecionado");
          return;
        }
        if (categoryId) {
          setError("Adicione a categoria selecionada");
          return;
        }
        if (selectedCategories.length < 1) {
          setError("Adicione pelo menos uma categoria");
          return;
        }

        const formData = new FormData();

        formData.append("nome", name.value);
        formData.append("preco", price.value.replace(",", "."));
        formData.append("descricao", description.value);
        formData.append("criador", owner.value);
        formData.append("estoque_atual", currentStock);
        formData.append("estoque_minimo", minimumStock);

        formData.append("itens", JSON.stringify(selectedItems));
        formData.append("categorias", JSON.stringify(selectedCategories));

        formData.append("imagem", image);

        const token = window.localStorage.getItem("token");

        const { url, options } = CREATE_PRODUCT(formData, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          await listProducts();
          onClose();
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (err) {
      setError(
        "Falha no cadastro. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={createProduct}>
      <h1>Cadastro de Produto</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Dono do Produto"
        {...owner}
        label="Proprietário"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Nome do Produto"
        {...name}
        label="Nome"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Preço do Produto"
        {...price}
        label="Preço"
      />
      <Textarea
        type="text"
        disabled={loading}
        title="Descrição do Produto"
        {...description}
        label="Descrição"
      />
      <ListContainer>
        <SubListContainer>
          <h1>Adicionar Categorias</h1>
          <Select
            disabled={loading}
            title="Categoria do Produto"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
          >
            <option value="">Selecione uma categoria</option>
            {categories.map((category) => (
              <option key={category?.id} value={category?.id}>
                {category?.nome}
              </option>
            ))}
          </Select>
          <Button
            type="button"
            disabled={loading}
            onClick={addCategoryToProduct}
          >
            Adicionar Categoria
          </Button>
        </SubListContainer>
        <h1>Categorias</h1>
        <ul>
          {selectedCategories.length > 0 ? (
            selectedCategories.map((category) => (
              <li
                key={category?.id}
                onClick={() => removeCategoryToProduct(category?.id)}
              >
                {category?.nome}{" "}
                <img width={25} src={imgDelete} alt="Icone de Delete" />
              </li>
            ))
          ) : (
            <p>Nenhum item selecionado</p>
          )}
        </ul>
      </ListContainer>
      <ListContainer>
        <SubListContainer>
          <h1>Adicionar Itens</h1>
          <Select
            disabled={loading}
            title="Item do Produto"
            value={itemId}
            onChange={(e) => setItemId(e.target.value)}
          >
            <option value="">Selecione um item</option>
            {items.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.nome}
              </option>
            ))}
          </Select>
          <Button type="button" disabled={loading} onClick={addItemToProduct}>
            Adicionar Item
          </Button>
        </SubListContainer>
        <h1>Itens</h1>
        <ul>
          {selectedItems.length > 0 ? (
            selectedItems.map((item) => (
              <li key={item?.id} onClick={() => removeItemToProduct(item?.id)}>
                {item?.nome}{" "}
                <img width={25} src={imgDelete} alt="Icone de Delete" />
              </li>
            ))
          ) : (
            <p>Nenhum item selecionado</p>
          )}
        </ul>
      </ListContainer>
      <ImageUpload
        label="Imagem Produto (Obrigatório)"
        loading={loading}
        setImage={setImage}
        imagePreview={imagePreview}
        setImagePreview={setImagePreview}
        onChange={() => {}}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Button type="submit" disabled={loading}>
        Salvar
      </Button>
    </Modal>
  );
};

export default ModalCreate;
