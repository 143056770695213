import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { FIND_USER } from "../../Api/Usuario";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();

  const [data, setData] = React.useState(null);
  const [errorUser, setErrorUser] = React.useState(null);
  const [loginUser, setLoginUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const userLogout = React.useCallback(() => {
    setData(null);
    setErrorUser(null);
    setLoginUser(false);
    window.localStorage.removeItem("token");
    navigate("/auth");
  }, [setData, setErrorUser, setLoginUser, navigate]);

  const featchUserToken = React.useCallback(async () => {
    const token = window.localStorage.getItem("token");
    if (!token) return;

    try {
      setLoading(true);
      setLoginUser(false);

      const { url, options } = FIND_USER(token);
      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        window.localStorage.setItem("token", responseJson.token);
        setData(responseJson.usuario);
        setLoginUser(true);

        return true;
      } else {
        userLogout();
        if (responseJson.erro) window.alert(responseJson.erro);
        if (responseJson.falha) window.alert(responseJson.falha);
      }
    } catch (errorUser) {
      userLogout();
      window.alert("Falha no busca do Usuário.");
    } finally {
      setLoading(false);
    }
  }, [userLogout]);

  return (
    <UserContext.Provider
      value={{
        data,
        setData,
        errorUser,
        setErrorUser,
        loginUser,
        setLoginUser,
        loading,
        setLoading,
        userLogout,
        featchUserToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserContext };
