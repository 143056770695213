import styled from "styled-components";

export const CatalogItemContainer = styled.div`
  max-width: 280px;
  width: 100%;

  max-height: 325px;
  height: 100%;

  padding: 5px;
  margin: 0 auto;

  cursor: pointer;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  gap: 5px;

  border-radius: 0.5rem;

  transition: box-shadow 0.3s ease-in-out;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

  &.destaque {
    position: relative;
    border: 2px solid #ffd700;

    > img {
      position: absolute;
      top: -5px;
      left: -5px;
      border-radius: 50%;
      background-color: #ffd700;
    }

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
    }
  }

  strong {
    width: 100%;

    color: ${({ theme }) => theme.colors.color_03};

    font-size: 1.75rem;
    font-weight: 400;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  div:nth-child(2) {
    max-width: 270px;
    width: 100%;

    max-height: 150px;
    height: 100%;

    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.background_02};

    img {
      max-height: 100%;
      height: 100%;

      margin: 0 auto;
      border-radius: 0.5rem;
    }
  }

  div:nth-child(3) {
    width: calc(100% - 10px);
    height: 100%;

    display: flex;
    flex-direction: column;

    align-items: center;

    gap: 5px;

    h3,
    ul,
    li,
    button {
      width: calc(100% - 10px);

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h1 {
      width: calc(100% - 10px);
      max-height: 60px;

      margin-top: auto;

      text-align: center;
      font-size: 1.25rem;
      font-weight: bold;

      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;

      line-height: 20px;

      color: ${({ theme }) => theme.colors.color_02};
    }

    h2 {
      width: 100%;

      text-align: center;
      font-size: 1.25rem;
      font-weight: 400;
    }

    h3 {
      width: 100%;

      text-align: center;
      font-size: 0.85rem;
      font-weight: 400;
    }

    span {
      width: calc(100% - 10px);

      padding: 2px;

      font-size: 1rem;
      text-align: center;

      border-color: ${({ theme }) => theme.colors.background_03};
      border-width: 1px;
      border-style: solid;
      border-radius: 0.5rem;
      background-color: ${({ theme }) => theme.colors.background_02};
    }

    ul {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: space-around;

      li {
        font-size: 0.75rem;
      }
    }
  }

  button {
    color: white;
  }
`;
