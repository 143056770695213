import React from "react";

import Modal from "../../../../../Components/Modal";
import Input from "../../../../../Components/Input";
import Button from "../../../../../Components/Button";

import { FIND_CATEGORY, UPDATE_CATEGORY } from "../../../../../Api/Categoria";

import useForm from "../../../../../Hook/useForm";

const ModalUpdate = ({
  isOpen,
  setIsOpen,
  categoryId,
  setCategoryId,
  fetchCategories,
}) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const name = useForm(true);
  const order = useForm(true);

  const onClose = () => {
    setIsOpen(false);
    setError("");
    name.setValue("");
    order.setValue("");
    setCategoryId(null);
  };

  const handleFindCategory = React.useCallback(
    async (id) => {
      if (!id) return;

      try {
        setLoading(true);
        setError("");

        const token = window.localStorage.getItem("token");

        const { url, options } = FIND_CATEGORY(id, token);

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          const categoryData = responseJson.categoria;
          name.setValue(categoryData.nome);
          order.setValue(categoryData.ordem);
        }
      } catch (error) {
        setError(
          "Falha ao buscar. Verifique suas credenciais e tente novamente."
        );
      } finally {
        setLoading(false);
      }
    },
    [name, order]
  );

  React.useEffect(() => {
    if (categoryId) handleFindCategory(categoryId);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [categoryId]);

  const handleUpdateCategory = async () => {
    try {
      setError("");
      setLoading(true);

      if (name.validation() && order.validation()) {
        const token = window.localStorage.getItem("token");

        const { url, options } = UPDATE_CATEGORY(
          categoryId,
          {
            nome: name.value,
            ordem: order.value,
          },
          token
        );

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          window.alert(responseJson.mensagem);
          await fetchCategories();
          onClose();
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } else {
        setError("Preencha todos os campos");
      }
    } catch (error) {
      setError(
        "Falha no cadastro. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleUpdateCategory}>
      <h1>Cadastro de Categoria</h1>
      <Input
        required
        type="text"
        disabled={loading}
        title="Nome da Categoria"
        {...name}
        label="Nome"
      />
      <Input
        required
        type="text"
        disabled={loading}
        title="Ordem da Categoria"
        {...order}
        label="Ordem"
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <Button type="submit" disabled={loading}>
        Salvar
      </Button>
    </Modal>
  );
};

export default ModalUpdate;
