import React from "react";

import {
  ModalCategoriesContainer,
  ModalSubCategoriesContainer,
} from "./styles";

import Modal from "../../../../../Components/Modal";
import Select from "../../../../../Components/Select";
import Button from "../../../../../Components/Button";

import imgDelete from "../../../../../Assets/delete(black).png";

import { ADD_CATEGORY, REMOVE_CATEGORY } from "../../../../../Api/Produto";

const ModalCategories = ({
  isOpen,
  setIsOpen,
  error,
  setError,
  loading,
  setLoading,
  categories,
  findProduct,
  listProducts,
  productData,
  setProductData,
}) => {
  const [categoryId, setCategoryId] = React.useState(null);

  const onClose = () => {
    setIsOpen(false);
    setError("");
    setLoading(false);
    setCategoryId(null);
    setProductData(null);
  };

  const handleAddCategoryToProduct = async () => {
    if (!categoryId || !productData?.id) {
      setError("Escolha uma categoria");
      return;
    }

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");
      const { url, options } = ADD_CATEGORY(productData?.id, categoryId, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        findProduct(productData?.id);
        listProducts();
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Erro ao associar a categoria ao produto. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCategoryToProduct = async (id) => {
    if (!id || !productData?.id) {
      setError("Escolha uma categoria");
      return;
    }

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");
      const { url, options } = REMOVE_CATEGORY(productData?.id, id, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        listProducts();
        findProduct(productData?.id);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Erro ao desassociar a categoria ao produto. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={() => {}}>
      <ModalCategoriesContainer>
        <h1>Categorias</h1>
        <ul>
          {productData?.categorias &&
            productData?.categorias.map((category) => (
              <li
                key={category?.id}
                onClick={() => handleRemoveCategoryToProduct(category?.id)}
              >
                {category?.nome}{" "}
                <img width={25} src={imgDelete} alt="Icone de Delete" />
              </li>
            ))}
        </ul>
        <ModalSubCategoriesContainer>
          <h1>Novas Categorias</h1>
          <Select
            required
            disabled={loading}
            title="Categoria do Produto"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
          >
            <option value="">Selecione uma categoria</option>
            {categories.map((category) => (
              <option key={category?.id} value={category?.id}>
                {category?.nome}
              </option>
            ))}
          </Select>
          <Button
            type="button"
            disabled={loading}
            onClick={() => handleAddCategoryToProduct()}
          >
            Adicionar Categoria
          </Button>
          {error && <span>{error}</span>}
        </ModalSubCategoriesContainer>
      </ModalCategoriesContainer>
    </Modal>
  );
};

export default ModalCategories;
