import styled from "styled-components";

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: start;

  padding: 0px 10px;

  > div:nth-child(1) {
    width: 100%;
    height: 40px;

    margin-top: 5px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: start;

    strong {
      width: 120px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;
      text-decoration: none;

      border-color: #ddd;
      border-style: solid;
      border-width: 2px 2px 0px 2px;

      background-color: ${({ theme }) => theme.colors.background_02};

      cursor: pointer;

      &:hover {
        width: 125px;
        height: 45px;
      }
    }

    strong:nth-child(1) {
      border-radius: 10px 0px 0px 0px;
    }

    .active {
      width: 125px;
      height: 45px;
      color: ${({ theme }) => theme.colors.text_02};
      border-color: ${({ theme }) => theme.colors.color_01};
      background-color: ${({ theme }) => theme.colors.color_01};
    }
  }

  /* > div:nth-child(1) {
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    h1,
    button {
      flex: 1;
      max-width: 300px;
    }

    h1 {
      font-weight: bold;
      margin-right: auto;
      color: ${({ theme }) => theme.colors.color_02};
    }

    button {
      margin-left: auto;
    }
  }

  > div:nth-child(3) {
    > div {
      > div:nth-child(7) {
        width: 100%;
        height: fit-content;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        gap: 5px;

        ul {
          width: 100%;
          padding-left: 10px;
          list-style-type: disc;
          li {
            display: flex;
            flex-direction: row;

            align-items: center;
            justify-content: space-between;

            button {
              width: 10%;
            }
          }
        }
      }

      > div:nth-child(8) {
        width: 100%;
        height: fit-content;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        > label,
        > div {
          width: 100%;
          height: fit-content;

          display: flex;
          flex-direction: column;

          align-items: center;
          justify-content: center;
        }
      }
    }
  } */

  /* table {
    width: 100%;
    border-collapse: collapse;

    button {
      width: fit-content;
    }
  }

  thead {
    background-color: #f4f4f4;
  }

  th,
  td {
    padding: 10px 5px;
    text-align: center;
    border-bottom: 2px solid #ddd;
  }

  thead th {
    background-color: #f4f4f4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 1%;
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tbody tr:hover {
    background-color: #f1f1f1;
  }

  tbody td {
    padding: 5px 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  table > tbody > tr > td:nth-child(5) {
    display: flex;
    flex-direction: row;

    gap: 10px;
    align-items: center;
    justify-content: center;
  } */
`;
