import React from "react";

import { CatalogItemContainer } from "./styles";

import Button from "../../../../Components/Button";

import iconStar from "../../../../Assets/star(white).png";

const CatalogItem = ({
  onClick,
  productName,
  numberItens,
  productPrice,
  productImage,
  highlight,
}) => {
  return (
    <CatalogItemContainer
      onClick={onClick}
      className={`${highlight === "Sim" ? "destaque" : ""}`}
    >
      {highlight === "Sim" ? (
        <img width={20} src={iconStar} alt="Icone de Estrela" />
      ) : (
        <img
          width={20}
          src={iconStar}
          alt="Icone de Estrela"
          style={{ display: "none" }}
        />
      )}
      <div>
        <img alt="Imagem do Produto" src={productImage} />
      </div>
      <div>
        <h1>{productName}</h1>
        {numberItens ? <h3>Contém {numberItens} item(s)</h3> : null}
        {productPrice === "0,00" ? (
          <span>Consulte</span>
        ) : (
          <span>R$ {productPrice}</span>
        )}
        <Button title="Ver mais detalhes do produto">Detalhes</Button>
      </div>
    </CatalogItemContainer>
  );
};

export default CatalogItem;
