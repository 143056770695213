import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;

  border-radius: 0px 0px 5px 5px;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    border-bottom: 1px solid #ddd;

    &:hover {
      background-color: ${({ theme }) => theme.colors.background_02};
    }

    th,
    td {
      flex: 1;
      min-width: 200px;
      min-height: 40px;
      text-align: center;
    }

    span {
      color: #ff7f50;
      padding: 10px;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  th {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    color: ${({ theme }) => theme.colors.text_02};
    background-color: ${({ theme }) => theme.colors.color_01};
  }

  td {
    width: 100%;
    height: 100%;

    padding: 5px 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    border-radius: 10px;

    /* position: relative;

    > img {
      position: absolute;
      right: 10px;
    } */

    > button {
      width: 40%;
      margin: 0px 5px;

      > img {
        margin: auto;
      }
    }
  }

  .openModal {
    cursor: pointer;
  }

  .openModal:hover {
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.color_01};
  }
`;
