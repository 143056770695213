import React from "react";

import { TableProuctsContainer } from "./styles";

import ModalCreate from "../ModalCreate";
import ModalUpdate from "../ModalUpdate";
import ModalItems from "../ModalItems";
import ModalImages from "../ModalImages";
import ModalCategories from "../ModalCategories";

import Table from "../../../../../Components/Table";
import Button from "../../../../../Components/Button";
import ToggleSwitch from "../../../../../Components/ToogleSwitch";

import imgUpdate from "../../../../../Assets/update(white).svg";
import imgDelete from "../../../../../Assets/delete(white).svg";
import imgPointer from "../../../../../Assets/pointer(black).svg";

import {
  FIND_PRODUCT,
  LIST_PRODUCTS,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "../../../../../Api/Produto";
import { LIST_CATEGORIES } from "../../../../../Api/Categoria";

const TableProducts = () => {
  const [items, setItems] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const [nameProduct, setNameProduct] = React.useState("");
  const [productData, setProductData] = React.useState(null);

  const [isOpenModalCreate, setIsOpenModalCreate] = React.useState(false);
  const [isOpenModalUpdate, setIsOpenModalUpdate] = React.useState(false);
  const [isOpenModalItem, setIsOpenModalItem] = React.useState(false);
  const [isOpenModalImage, setIsOpenModalImage] = React.useState(false);
  const [isOpenModalCategory, setIsOpenModalCategory] = React.useState(false);

  const listItems = React.useCallback(async () => {
    setProducts([]);

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");

      const { url, options } = LIST_PRODUCTS(token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setItems(responseJson.produtos);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const listProducts = React.useCallback(async () => {
    setProducts([]);

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");

      const { url, options } = LIST_PRODUCTS(token, nameProduct);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setProducts(responseJson.produtos);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [nameProduct]);

  const listCategories = React.useCallback(async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const { url, options } = LIST_CATEGORIES(token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setCategories(responseJson.categorias);
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError(
        "Falha na listagem de categorias. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    listItems();
    listProducts();
    listCategories();
  }, [listItems, listProducts, listCategories]);

  const findProduct = React.useCallback(async (id) => {
    if (!id) return;

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");

      const { url, options } = FIND_PRODUCT(id, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        setProductData(responseJson.produto);
      }
    } catch (error) {
      setError(
        "Falha ao buscar. Verifique suas credenciais e tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateProduct = async (productId, visivel) => {
    if (!productId) return;

    const token = window.localStorage.getItem("token");

    const formData = new FormData();
    formData.append("visivel", JSON.stringify(visivel));

    const { url, options } = UPDATE_PRODUCT(productId, formData, token);

    const response = await fetch(url, options);
    const responseJson = await response.json();

    if (responseJson.ok === "true") {
      let status;

      if (visivel === 1) {
        status = 0;
      } else {
        status = 1;
      }

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId ? { ...product, visivel: status } : product
        )
      );
    } else {
      if (responseJson.erro) window.alert(responseJson.erro);
      if (responseJson.falha) window.alert(responseJson.falha);
    }
  };

  const handleDeleteProduct = async (id) => {
    if (!id) return;

    const confirmed = window.confirm(
      "Tem certeza de que deseja excluir este produto?"
    );

    if (!confirmed) {
      return;
    }

    const token = window.localStorage.getItem("token");

    const { url, options } = DELETE_PRODUCT(id, token);

    const response = await fetch(url, options);
    const responseJson = await response.json();

    if (responseJson.ok === "true") {
      await listProducts();
    } else {
      if (responseJson.erro) window.alert(responseJson.erro);
      if (responseJson.falha) window.alert(responseJson.falha);
    }
  };

  return (
    <TableProuctsContainer>
      <div>
        <input
          type="text"
          placeholder="Nome do Produto"
          value={nameProduct}
          onChange={(e) => setNameProduct(e.target.value)}
        />
        <Button type="button" onClick={() => setIsOpenModalCreate(true)}>
          Novo Produto
        </Button>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Preço</th>
            <th>Nº Itens</th>
            <th>Nº Imagens</th>
            <th>Nº Categorias</th>
            <th>Visualização</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {error ? (
            <tr>
              <span>{error}</span>
            </tr>
          ) : (
            products.length > 0 &&
            products.map((product, index) => (
              <tr key={index}>
                <td>{product?.nome}</td>
                <td>R$ {product?.preco}</td>
                <td
                  className="openModal"
                  onClick={() => {
                    if (!product?.id) return;
                    findProduct(product?.id);
                    setIsOpenModalItem(true);
                  }}
                >
                  {product?.numero_itens} itens{" "}
                  <img
                    src={imgPointer}
                    width={20}
                    height={20}
                    alt="Editar Itens"
                  />
                </td>
                <td
                  className="openModal"
                  onClick={() => {
                    if (!product?.id) return;
                    findProduct(product?.id);
                    setIsOpenModalImage(true);
                  }}
                >
                  {product?.numero_imagens} imagens{" "}
                  <img
                    src={imgPointer}
                    width={20}
                    height={20}
                    alt="Editar Imagens"
                  />
                </td>
                <td
                  className="openModal"
                  onClick={() => {
                    if (!product?.id) return;
                    findProduct(product?.id);
                    setIsOpenModalCategory(true);
                  }}
                >
                  {product?.numero_categorias} categorias{" "}
                  <img
                    src={imgPointer}
                    width={20}
                    height={20}
                    alt="Editar Categorias"
                  />
                </td>
                <td>
                  <ToggleSwitch
                    isActive={product?.visivel === 1 ? true : false}
                    onToggle={() => {
                      handleUpdateProduct(product?.id, product?.visivel);
                    }}
                  />
                </td>
                <td>
                  <Button
                    type="button"
                    title="Editar produto"
                    onClick={() => {
                      if (!product?.id) return;
                      findProduct(product?.id);
                      setIsOpenModalUpdate(true);
                    }}
                  >
                    <img
                      width="26"
                      height="26"
                      src={imgUpdate}
                      alt="Editar Produto"
                    />
                  </Button>
                  <Button
                    type="button"
                    title="Excluir produto"
                    onClick={() => handleDeleteProduct(product?.id)}
                  >
                    <img
                      width="26"
                      height="26"
                      src={imgDelete}
                      alt="Excluir Produto"
                    />
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <ModalCreate
        isOpen={isOpenModalCreate}
        setIsOpen={setIsOpenModalCreate}
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        items={items}
        categories={categories}
        listProducts={listProducts}
      />
      <ModalUpdate
        isOpen={isOpenModalUpdate}
        setIsOpen={setIsOpenModalUpdate}
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        listProducts={listProducts}
        productData={productData}
        setProductData={setProductData}
      />
      <ModalItems
        isOpen={isOpenModalItem}
        setIsOpen={setIsOpenModalItem}
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        items={items}
        findProduct={findProduct}
        listProducts={listProducts}
        productData={productData}
        setProductData={setProductData}
      />
      <ModalImages
        isOpen={isOpenModalImage}
        setIsOpen={setIsOpenModalImage}
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        findProduct={findProduct}
        listProducts={listProducts}
        productData={productData}
      />
      <ModalCategories
        isOpen={isOpenModalCategory}
        setIsOpen={setIsOpenModalCategory}
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        categories={categories}
        findProduct={findProduct}
        listProducts={listProducts}
        productData={productData}
        setProductData={setProductData}
      />
    </TableProuctsContainer>
  );
};

export default TableProducts;
