import React from "react";

import { ProductListContainer } from "./styles";

import TableProducts from "./Components/Product/Table";
import TableCategories from "./Components/Category/Table";

import useUser from "../../Context/User/useUser";

export const Tables = () => {
  const [table, setTable] = React.useState("product");

  const { featchUserToken } = useUser();

  React.useEffect(() => {
    featchUserToken();
  }, [featchUserToken]);

  return (
    <ProductListContainer>
      <div>
        <strong
          className={table === "product" ? "active" : ""}
          onClick={() => {
            setTable("product");
          }}
        >
          Produtos
        </strong>
        <strong
          className={table === "category" ? "active" : ""}
          onClick={() => {
            setTable("category");
          }}
        >
          Categorias
        </strong>
      </div>
      {(() => {
        switch (table) {
          case "product":
            console.log(table);
            return <TableProducts />;
          case "category":
            return <TableCategories />;
          default:
            return null;
        }
      })()}
    </ProductListContainer>
  );
};
