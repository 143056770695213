import React from "react";
import { useNavigate } from "react-router-dom";

import { AUTH_USER } from "../../Api/Usuario";

import { AuthContainer } from "./styles";

import Input from "../../Components/Input";
import Button from "../../Components/Button";

import useForm from "../../Hook/useForm";

import useUser from "../../Context/User/useUser";

export const Auth = () => {
  const navigate = useNavigate();

  const email = useForm("email");
  const password = useForm("password");

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const { featchUserToken } = useUser();

  React.useEffect(() => {
    const checkUserToken = async () => {
      const result = await featchUserToken();
      if (result) navigate("/tables");
    };
    checkUserToken();
  }, [featchUserToken, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (email.validation() && password.validation()) {
      setLoading(true);

      try {
        const { url, options } = AUTH_USER({
          email: email.value,
          senha: password.value,
        });

        const response = await fetch(url, options);
        const responseJson = await response.json();

        if (responseJson.ok === "true") {
          localStorage.setItem("token", responseJson.token);
          navigate("/tables");
        } else {
          if (responseJson.erro) setError(responseJson.erro);
          if (responseJson.falha) setError(responseJson.falha);
        }
      } catch (err) {
        setError("Falha no servidor. Tente novamente mais tarde.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <AuthContainer>
      <form action="submit" onSubmit={handleSubmit}>
        <h1>Autenticação</h1>
        <p>Autenticação apenas para usuários administradores</p>
        <Input
          required
          type="email"
          disabled={loading}
          {...email}
          label="E-mail"
        />
        <Input
          required
          type="password"
          disabled={loading}
          {...password}
          label="Senha"
        />
        <Button
          type="submit"
          title="Entrar"
          disabled={loading}
          onClick={handleSubmit}
        >
          Entrar
        </Button>
        {error && <p>{error}</p>}
      </form>
    </AuthContainer>
  );
};
