import React from "react";

import { ModalItemsContainer, ModalSubItemsContainer } from "./styles";

import Modal from "../../../../../Components/Modal";
import Select from "../../../../../Components/Select";
import Button from "../../../../../Components/Button";

import imgDelete from "../../../../../Assets/delete(black).png";

import { ADD_ITEM, REMOVE_ITEM } from "../../../../../Api/Produto";

const ModalItems = ({
  isOpen,
  setIsOpen,
  error,
  setError,
  loading,
  setLoading,
  items,
  findProduct,
  listProducts,
  productData,
  setProductData,
}) => {
  const [itemId, setItemId] = React.useState(null);

  const onClose = () => {
    setIsOpen(false);
    setError("");
    setLoading(false);
    setItemId(null);
    setProductData(null);
  };

  const handleAddItemToProduct = async () => {
    if (!itemId || !productData?.id) {
      setError("Escolha um item");
      return;
    }

    if (JSON.parse(itemId) === productData?.id) {
      setError("Não é possivel adicionar o mesmo produto como item");
      return;
    }

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");
      const { url, options } = ADD_ITEM(productData?.id, itemId, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        findProduct(productData?.id);
        listProducts();
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Erro ao associar a categoria ao produto. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveItemToProduct = async (id) => {
    if (!id || !productData?.id) {
      setError("Escolha uma categoria");
      return;
    }

    try {
      setLoading(true);
      setError("");

      const token = window.localStorage.getItem("token");
      const { url, options } = REMOVE_ITEM(productData?.id, id, token);

      const response = await fetch(url, options);
      const responseJson = await response.json();

      if (responseJson.ok === "true") {
        findProduct(productData?.id);
        listProducts();
      } else {
        if (responseJson.erro) setError(responseJson.erro);
        if (responseJson.falha) setError(responseJson.falha);
      }
    } catch (err) {
      setError("Erro ao desassociar a categoria ao produto. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={() => {}}>
      <ModalItemsContainer>
        <h1>Itens</h1>
        <ul>
          {productData?.itens &&
            productData?.itens.map((item) => (
              <li
                key={item?.id}
                onClick={() => handleRemoveItemToProduct(item?.id)}
              >
                {item?.nome}{" "}
                <img width={25} src={imgDelete} alt="Icone de Delete" />
              </li>
            ))}
        </ul>
        <ModalSubItemsContainer>
          <h1>Adicionar Itens</h1>
          <Select
            required
            disabled={loading}
            title="Item do Produto"
            value={itemId}
            onChange={(e) => setItemId(e.target.value)}
          >
            <option value="">Selecione uma item</option>
            {items.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.nome}
              </option>
            ))}
          </Select>
          <Button
            type="button"
            disabled={loading}
            onClick={() => handleAddItemToProduct()}
          >
            Adicionar Item
          </Button>
          {error && <span>{error}</span>}
        </ModalSubItemsContainer>
      </ModalItemsContainer>
    </Modal>
  );
};

export default ModalItems;
