import IconeCesta from "../../Assets/shopping-basket(black).svg";

import { API_URL } from "..";

const API_URL_PRODUCT = API_URL + "produtos";

export function CREATE_PRODUCT(formData, token) {
  return {
    url: `${API_URL_PRODUCT}/cadastrar`,
    options: {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function FIND_PRODUCT(productId, token) {
  return {
    url: `${API_URL_PRODUCT}/buscar?produto_id=${productId}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function LIST_PRODUCTS(token, name, visivel, categoryId) {
  let query = "";

  if (name || visivel || categoryId) {
    query = "?";
    if (name) query += `nome=${name}&`;
    if (visivel) query += `visivel=${visivel}&`;
    if (categoryId) query += `categoria_id=${categoryId}&`;
    query = query.slice(0, -1);
  }

  return {
    url: `${API_URL_PRODUCT}/listar${query}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function CATALOG_PRODUCTS(token, name, visivel, categoryId) {
  let query = "";

  if (name || visivel || categoryId) {
    query = "?";
    if (name) query += `nome=${name}&`;
    if (visivel) query += `visivel=${visivel}&`;
    if (categoryId) query += `categoria_id=${categoryId}&`;
    query = query.slice(0, -1);
  }

  return {
    url: `${API_URL_PRODUCT}/catalogo${query}`,
    options: {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function IMAGE_PRODUCT(image) {
  if (image) {
    return API_URL_PRODUCT + "/imagem_produto/" + image;
  } else {
    return IconeCesta;
  }
}

export function UPDATE_PRODUCT(productId, formData, token) {
  return {
    url: `${API_URL_PRODUCT}/atualizar?produto_id=${productId}`,
    options: {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function ADD_CATEGORY(productId, categoriaId, token) {
  const query = `?produto_id=${productId}&categoria_id=${categoriaId}`;

  return {
    url: `${API_URL_PRODUCT}/adicionar-categoria${query}`,
    options: {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function REMOVE_CATEGORY(productId, categoriaId, token) {
  const query = `?produto_id=${productId}&categoria_id=${categoriaId}`;

  return {
    url: `${API_URL_PRODUCT}/remover-categoria${query}`,
    options: {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function ADD_ITEM(productId, itemId, token) {
  const query = `?produto_id=${productId}&item_id=${itemId}`;

  return {
    url: `${API_URL_PRODUCT}/adicionar-item${query}`,
    options: {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function REMOVE_ITEM(productId, itemId, token) {
  const query = `?produto_id=${productId}&item_id=${itemId}`;

  return {
    url: `${API_URL_PRODUCT}/remover-item${query}`,
    options: {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}

export function UPDATE_PRODUCT_IMAGE(productId, formData, token) {
  return {
    url: `${API_URL_PRODUCT}/atualizar-imagem?produto_id=${productId}`,
    options: {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: formData,
    },
  };
}

export function DELETE_PRODUCT(productId, token) {
  return {
    url: `${API_URL_PRODUCT}/excluir?produto_id=${productId}`,
    options: {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  };
}
