import styled from "styled-components";

export const HeaderContainer = styled.header`
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  > div:nth-child(1) {
    width: 100%;
    padding: 5px 10px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    background-color: ${({ theme }) => theme.colors.color_01};

    user-select: none;

    > img {
      border-radius: 25%;

      cursor: pointer;

      &:hover {
        border-width: 2px;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.color_01};
      }
    }

    > a {
      font-size: 2rem;
      font-style: italic;
      text-align: center;
      font-family: "Great Vibes", cursive;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.text_02};

      &:hover {
        font-weight: bold;
      }
    }
  }

  > div:nth-child(2) {
    width: 100%;
    padding: 5px 10px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    > p {
      text-align: center;
      font-size: 1.75rem;
      font-weight: normal;
      font-family: "Great Vibes", cursive;
      color: ${({ theme }) => theme.colors.color_02};

      user-select: none;
    }

    > div {
      width: 100%;

      display: flex;
      flex-direction: row;

      gap: 5px;
      align-items: center;
      justify-content: center;

      > a {
        display: flex;
        flex-direction: row;

        gap: 3px;
        align-items: center;
        justify-content: center;

        text-align: center;
        text-decoration: none;

        color: ${({ theme }) => theme.colors.text_01};

        &:hover {
          font-weight: bold;
        }
      }

      @media (max-width: 695px) {
        flex-direction: column;
      }
    }
  }

  .dropdown-menu {
    padding: 10px;
    z-index: 2;

    position: absolute;
    top: 55px;
    right: 10px;

    display: none;
    flex-direction: column;

    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    background-color: ${({ theme }) => theme.colors.background_01};

    &.active {
      display: flex;
    }

    > p {
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.colors.color_02};
      }
    }
  }
`;
