import styled from "styled-components";

export const CatalogContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  /* 
  > div:nth-child(1) {
    width: 100%;
    height: 200px !important;

    background-color: #ddd;
  } */

  > div:nth-child(2) {
    width: 100%;
    height: 40px;

    padding: 0px 5px;
    z-index: 1;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    color: #8c8c8c;

    background-color: ${({ theme }) => theme.colors.background_02};

    user-select: none;

    p {
      width: 100%;
      text-align: center;
    }

    strong {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.color_02};
      &:hover {
        color: ${({ theme }) => theme.colors.color_03};
      }
    }
  }
`;

export const CatalogSubContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  align-items: start;

  overflow-y: hidden;

  > div:nth-child(2) {
    width: 100%;
    height: calc(100% + 10px);

    padding: 10px 5px;

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(0, 280px));

    align-items: start;
    justify-content: center;

    gap: 10px;

    overflow-y: auto;

    strong {
      width: 150px;
      position: fixed;
      top: 50%;
      left: calc((50% - (200px / 2)) + 50px);
      text-align: center;
    }

    > div {
      flex: 1;
    }
  }

  /* position: relative; */
`;

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 200px;
  overflow: hidden;
`;

export const CarouselButton = styled.button`
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  ${({ left }) => (left ? "left: 10px;" : "right: 10px;")}
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 1;
`;

export const ImageWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

export const Image = styled.img`
  width: 200px;
  height: auto;
  object-fit: cover;
`;
